<template lang="pug">
VueHcaptcha.FcwHcaptcha(
  ref="componentRef"
  :sitekey
  size="invisible"
  @error="handleCaptchaError"
  @verify="handleCaptchaVerify"
  @expired="handleTokenExpired"
  @challenge-expired="handleChallengeExpired"
)
</template>

<script setup lang="ts">
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

import { useHcaptcha } from '@/composables/useHcaptcha';

const componentRef = ref<VueHcaptcha>();

const {
  sitekey,
  handleCaptchaError,
  handleCaptchaVerify,
  handleTokenExpired,
  handleChallengeExpired,
  init,
} = useHcaptcha();

onMounted(() => {
  init(componentRef);
});
</script>
