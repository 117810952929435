import { CwAppErrorCode } from '@/models/enums';

import type VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

interface UseHCaptchaReturn {
  /**
   * Sitekey for hcaptcha
   */
  sitekey: string;
  /**
   * Passcode given by hcaptcha on successful verification
   */
  passcode: Ref<string>;
  /**
   * Whether the passcode has expired
   */
  isPasscodeExpired: Ref<boolean>;
  /**
   * Handle verify event fired by hcaptcha
   * @param token - The passcode provided by hcaptcha
   */
  handleCaptchaVerify: (token: string) => void;
  /**
   * Handle error event fired by hcaptcha
   */
  handleCaptchaError: () => void;
  /**
   * Handle `expired` event fired by hcaptcha
   */
  handleTokenExpired: () => void;
  /**
   * Handle `challenge-expired` event fired by hcaptcha
   */
  handleChallengeExpired: () => void;
  /**
   * Trigger hcaptcha challenge programmatically
   */
  triggerCaptcha: () => Promise<void>;
  /**
   * Initialize the hcaptcha component
   * @param componentRef - Reference to the hcaptcha component
   */
  init: (componentRef: Ref<VueHcaptcha | undefined>) => void;
}

/**
 * Composable to handle features related to hcaptcha
 * This composable is made to be used with single instance of FcwHcaptcha
 */
export function useHcaptcha(): UseHCaptchaReturn {
  const config = useRuntimeConfig();
  const sitekey = config.public.hcaptchaSitekey;

  const passcode = useState('hcaptchaPasscode', () => '');
  const isPasscodeExpired = useState('isPasscodeExpired', () => false);
  const captchaRef = useState<VueHcaptcha | undefined>(
    'captchaRef',
    () => undefined
  );

  function init(componentRef: Ref<VueHcaptcha | undefined>): void {
    captchaRef.value = componentRef.value;
  }

  function handleTokenExpired(): void {
    return useErrorAlert(CwAppErrorCode.CaptchaTokenExpired);
  }

  function handleChallengeExpired(): void {
    isPasscodeExpired.value = true;
    passcode.value = '';
    captchaRef.value?.reset();
    return useErrorAlert(CwAppErrorCode.CaptchaChallengeExpired);
  }

  function handleCaptchaVerify(token: string): void {
    passcode.value = token;
  }

  function handleCaptchaError(): void {
    return useErrorAlert(CwAppErrorCode.CaptchaSomethingWentWrong);
  }

  async function triggerCaptcha(): Promise<void> {
    // Programmatically trigger the hcaptcha challenge
    await captchaRef.value?.executeAsync();

    if (!passcode.value) {
      return useErrorAlert(CwAppErrorCode.CaptchaNotVerified);
    }

    isPasscodeExpired.value = false;
  }

  return {
    sitekey,
    passcode,
    isPasscodeExpired,
    triggerCaptcha,
    handleCaptchaVerify,
    handleCaptchaError,
    handleTokenExpired,
    handleChallengeExpired,
    init,
  };
}
