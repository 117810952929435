export default {
  "account-settings-auth": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/account-settings-auth.vue"),
  "account-settings": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/account-settings.vue"),
  "communication-preferences": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/communication-preferences.vue"),
  embed: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/embed.vue"),
  empty: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/empty.vue"),
  "profile-auth": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-auth.vue"),
  "profile-discover": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-discover.vue"),
  "profile-register": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-register.vue"),
  profile: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile.vue"),
  showcase: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/showcase.vue")
}