function mapRedirectTypeToStatusCode(type: RedirectType): RedirectStatusCode {
  switch (type) {
    case 'found_302':
      return 302;
    case 'moved_permanently_301':
      return 301;
    case 'temporary_redirect_307':
      return 307;
    case 'gone_410':
      return 410;
    case 'unavailable_for_legal_reasons_451':
      return 451;
  }
}

export function mapCmsRedirectsDataToRedirects(
  redirects: RedirectItem[] | undefined
): Redirect[] {
  return (redirects ?? []).map(redirect => {
    const { from, to, type } = redirect;
    return {
      from,
      to,
      statusCode: mapRedirectTypeToStatusCode(type),
    };
  });
}
