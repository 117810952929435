import { mapCmsRedirectsDataToRedirects } from '@/lib/cms-mappers';
import { useStrapi } from '@/composables/useStrapi';
/**
 * Returns redirects data from Strapi CMS
 */
export async function useCmsRedirects(): UseCmsResponse<Redirect[]> {
  const { data, error, pending, refresh } = await useStrapi('redirects');

  const mappedData = computed(() =>
    mapCmsRedirectsDataToRedirects(data.value?.redirects)
  );

  return { data: mappedData, error, pending, refresh };
}
