import { ApiErrorCode } from '@fifteen/sdk';

/* When editing this file, please make sure to keep the Notion documentation synced:
 ** https://www.notion.so/SWS-Errors-e35c206e6b2841ef95516c9e0769c475?pvs=4
 */

export enum CwAppErrorCode {
  // Internal: I0000
  InternalIssue = 'CWA_I0001',
  UserOfferNotFound = 'CWA_I0002',
  UserOfferSecretNotFound = 'CWA_I0003',
  MissingPaymentMethod = 'CWA_I0004',
  OfferSubscriptionMissingRequiredDocument = 'CWA_I0005',
  OfferSubscriptionMissingPaymentMethod = 'CWA_I0006',
  OfferSubscriptionMissingDeliveryAddress = 'CWA_I0007',
  OfferSubscriptionMissingElement = 'CWA_I0008',
  PaymentMethodTimeout = 'CWA_I0009',
  PaymentMethodChallengeFailed = 'CWA_I0010',
  UnsupportedPaymentProvider = 'CWA_I0011',
  RentalNotFound = 'CWA_I0012',
  UserGeolocationFailed = 'CWA_I0013',
  CaptchaNotVerified = 'CWA_I0014',
  CaptchaSomethingWentWrong = 'CWA_I0015',
  CaptchaChallengeExpired = 'CWA_I0016',
  CaptchaTokenExpired = 'CWA_I0017',

  // Stripe (Vendor): V1000
  StripeCardPaymentConfirmationFailed = 'CWA_V1001',
  StripeAddPaymentCardFailed = 'CWA_V1002',
  StripeConfirmPaymentCardFailed = 'CWA_V1003',
  StripeInstanciationFailed = 'CWA_V1004',
  StripeAddPaypalFailed = 'CWA_V1005',
  StripeConfirmPaymentPaypalFailed = 'CWA_V1006',

  // Monext (Vendor): V4000
  MonextInvalidMonextContractNumber = 'CWA_V4001',
  MonextNoCardFoundForThisToken = 'CWA_V4002',
  MonextMaximumAttemptsExceeded = 'CWA_V4003',
  MonextExpiredCard = 'CWA_V4004',
  MonextInvalidCardNumber = 'CWA_V4005',
  MonextInvalidExpirationDate = 'CWA_V4006',
  MonextInvalidCVC = 'CWA_V4007',
  MonextUnauthorizedAction = 'CWA_V4008',
  MonextInvalidToken = 'CWA_V4009',
  MonextUnauthorizedAccess = 'CWA_V4010',
  MonextAddPaymentMethodFailed = 'CW_V4011',
}

export enum CwServerErrorCode {
  // Internal: I0000
  InternalIssue = 'CWS_I0001',
  InternalMissingSessionId = 'CWS_I0002',
  InternalUserNotConnected = 'CWS_I0003',
  InternalItemNotFound = 'CWS_I0004',
  InternalPaginationBadLimitQueryParam = 'CWS_I0005',
  InternalPaginationBadPageQueryParam = 'CWS_I0006',
  InternalSchemaValidationError = 'CWS_I0007',
  InternalMissingMapIcon = 'CWS_I0008',

  // Backend: B0000
  BackendUnhandledIssue = 'CWS_B0001',
  BackendMissingClientScopeId = 'CWS_B0002',

  // Stripe (Vendor): V1000
  StripePaymentMethodCreationFailed = 'CWS_V1001',
  StripePaymentMethodCannotBeUsed = 'CWS_V1002',

  // Zendesk (Vendor): V2000
  ZendeskTicketCreationMissingEmailAddress = 'CWS_V2001',
  ZendeskFetchHelpCenterIssue = 'CWS_V2002',
  ZendeskFetchHelpCenterMissingArticle = 'CWS_V2003',
  ZendeskUploadBadAttachmentBody = 'CWS_V2004',
  ZendeskUnhandledIssue = 'CWS_V2005',

  // Google Places (Vendor): V3000
  GooglePlacesPlaceAutocompleteIssue = 'CWS_V3001',
  GooglePlacesPlaceAutocompletePredictionsIssue = 'CWS_V3002',
  GooglePlacesPlaceDetailsIssue = 'CWS_V3003',
}

// External API error codes
// Used for typing, but should always be remapped to our own error codes

// Monext
// https://docs.monext.fr/pages/viewpage.action?pageId=747146776
export enum MonextErrorCode {
  InvalidContractNumber = '02303',
  NoCardFoundForThisToken = '02540',
  MaximumAttemptsExceeded = '02623',
  ExpiredCard = '02624',
  InvalidCardNumber = '02625',
  InvalidExpirationDate = '02626',
  InvalidCVC = '02627',
  UnauthorizedAction = '02703',
  InvalidToken = '02713',
  UnauthorizedAccess = '09101',
}

export type BackendServicesErrorCode = ApiErrorCode;
export const BackendServicesErrorCode = ApiErrorCode;

export enum CouponStatus {
  Active = 0,
  Expired = 1,
  Consumed = 2,
}

export enum OTPMode {
  Phone = 'phone',
  Email = 'email',
}

export enum AppModule {
  Showcase = 'showcase',
  Profile = 'profile',
}

export enum ZendeskTicketContext {
  SubscriptionProof,
  ContactForm,
}

export enum ProductRetrieval {
  Station = 'station',
  Delivery = 'delivery',
}

// TODO: move to @fifteen/sdk
// See: https://zoov-eu.atlassian.net/browse/SVC-7281
export enum AppTripError {
  ImpossibleToUnlock = 10,
}

// TODO: move to @fifteen/sdk
// See: https://zoov-eu.atlassian.net/browse/SVC-7281
export enum BackendTripError {
  InvalidBikeState = 4242,
}

export enum ServiceType {
  Sharing = 'sharing',
  Leasing = 'leasing',
}

export enum PointOfInterestType {
  Unknown = 'unknown',
  BikeHouse = 'bike_house',
  BikeLocker = 'bike_locker',
}

export enum DirectionMapboxProfile {
  Walking = 'mapbox/walking',
  Cycling = 'mapbox/cycling',
}
