import { default as confirmationeZz7E42P8WMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/auth/confirmation.vue?macro=true";
import { default as indexLqa671bMwYMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/auth/index.vue?macro=true";
import { default as indexLJ94GVJclBMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/index.vue?macro=true";
import { default as indexdBkakprHMxMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/communication-preferences/index.vue?macro=true";
import { default as mapO8Ghzmdmd7Meta } from "/tmp/build/ad8838f6/customer-website-pr/pages/embed/map.vue?macro=true";
import { default as legal_45noticeNzjtBlpmeWMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/legal-notice.vue?macro=true";
import { default as privacy_45policyvsyiboyGp4Meta } from "/tmp/build/ad8838f6/customer-website-pr/pages/privacy-policy.vue?macro=true";
import { default as confirmation5RVdbAfhb3Meta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/auth/confirmation.vue?macro=true";
import { default as indexOqvfXNEMWJMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/auth/index.vue?macro=true";
import { default as discoverU55FLBDGRzMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/discover.vue?macro=true";
import { default as indextiREbfaChNMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/index.vue?macro=true";
import { default as _91id_9389rC13Yd3SMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/offers/[id].vue?macro=true";
import { default as indexoR2aj49jALMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/offers/index.vue?macro=true";
import { default as indexJ4YjXy2aHDMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/payments/index.vue?macro=true";
import { default as confirmationoWXo3S5WaJMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/confirmation.vue?macro=true";
import { default as identity0WHRuIoj5XMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/identity.vue?macro=true";
import { default as index1ETDunKVSzMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/index.vue?macro=true";
import { default as payment_45methodmi9rWHEDfHMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/payment-method.vue?macro=true";
import { default as transport_45cardyLTTu9RYxNMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/transport-card.vue?macro=true";
import { default as tripsuymEWa7ITaMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/profile/trips.vue?macro=true";
import { default as leasing5FODBNVvN1Meta } from "/tmp/build/ad8838f6/customer-website-pr/pages/referral/leasing.vue?macro=true";
import { default as sharingKaYMTvQS4rMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/referral/sharing.vue?macro=true";
import { default as _91slug_934QdgsP0JFgMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/[slug].vue?macro=true";
import { default as aboutuFDbfQmJVSMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/about.vue?macro=true";
import { default as _91slug_93tFX4DcQbseMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/[slug].vue?macro=true";
import { default as indexS91cPXaPJVMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/index.vue?macro=true";
import { default as helpEfXlHUDK9GMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/help.vue?macro=true";
import { default as indexeWt6Q0yuWpMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/index.vue?macro=true";
import { default as leasingCiCnjeZC8wMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/leasing.vue?macro=true";
import { default as mapi1KJwhGxjHMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/map.vue?macro=true";
import { default as sharing1jtvSw0MiFMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/showcase/sharing.vue?macro=true";
import { default as terms_45and_45conditionsVjQz7NF1YSMeta } from "/tmp/build/ad8838f6/customer-website-pr/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubEAEw0RKGXaMeta } from "/tmp/build/ad8838f6/customer-website-pr/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubEAEw0RKGXa } from "/tmp/build/ad8838f6/customer-website-pr/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-settings-auth-confirmation",
    path: "/account-settings/auth/confirmation",
    meta: confirmationeZz7E42P8WMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/auth/confirmation.vue")
  },
  {
    name: "account-settings-auth",
    path: "/account-settings/auth",
    meta: indexLqa671bMwYMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/auth/index.vue")
  },
  {
    name: "account-settings",
    path: "/account-settings",
    meta: indexLJ94GVJclBMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/account-settings/index.vue")
  },
  {
    name: "communication-preferences",
    path: "/communication-preferences",
    meta: indexdBkakprHMxMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/communication-preferences/index.vue")
  },
  {
    name: "embed-map",
    path: "/embed/map",
    meta: mapO8Ghzmdmd7Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/embed/map.vue")
  },
  {
    name: "unlocalized-legal-notice",
    path: "/legal-notice",
    meta: legal_45noticeNzjtBlpmeWMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/legal-notice.vue")
  },
  {
    name: "unlocalized-privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyvsyiboyGp4Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/privacy-policy.vue")
  },
  {
    name: "profile-auth-confirmation",
    path: "/profile/auth/confirmation",
    meta: confirmation5RVdbAfhb3Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/auth/confirmation.vue")
  },
  {
    name: "profile-auth",
    path: "/profile/auth",
    meta: indexOqvfXNEMWJMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/auth/index.vue")
  },
  {
    name: "profile-discover",
    path: "/profile/discover",
    meta: discoverU55FLBDGRzMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/discover.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indextiREbfaChNMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/index.vue")
  },
  {
    name: "profile-offers-id",
    path: "/profile/offers/:id()",
    meta: _91id_9389rC13Yd3SMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/offers/[id].vue")
  },
  {
    name: "profile-offers",
    path: "/profile/offers",
    meta: indexoR2aj49jALMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/offers/index.vue")
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexJ4YjXy2aHDMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/payments/index.vue")
  },
  {
    name: "profile-register-confirmation",
    path: "/profile/register/confirmation",
    meta: confirmationoWXo3S5WaJMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/confirmation.vue")
  },
  {
    name: "profile-register-identity",
    path: "/profile/register/identity",
    meta: identity0WHRuIoj5XMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/identity.vue")
  },
  {
    name: "profile-register",
    path: "/profile/register",
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/index.vue")
  },
  {
    name: "profile-register-payment-method",
    path: "/profile/register/payment-method",
    meta: payment_45methodmi9rWHEDfHMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/payment-method.vue")
  },
  {
    name: "profile-register-transport-card",
    path: "/profile/register/transport-card",
    meta: transport_45cardyLTTu9RYxNMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/register/transport-card.vue")
  },
  {
    name: "profile-trips",
    path: "/profile/trips",
    meta: tripsuymEWa7ITaMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/profile/trips.vue")
  },
  {
    name: "unlocalized-referral-leasing",
    path: "/referral/leasing",
    meta: leasing5FODBNVvN1Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/referral/leasing.vue")
  },
  {
    name: "unlocalized-referral-sharing",
    path: "/referral/sharing",
    meta: sharingKaYMTvQS4rMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/referral/sharing.vue")
  },
  {
    name: "unlocalized-showcase-slug",
    path: "/:slug()",
    meta: _91slug_934QdgsP0JFgMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/[slug].vue")
  },
  {
    name: "unlocalized-showcase-about",
    path: "/about",
    meta: aboutuFDbfQmJVSMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/about.vue")
  },
  {
    name: "unlocalized-showcase-blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93tFX4DcQbseMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/[slug].vue")
  },
  {
    name: "unlocalized-showcase-blog",
    path: "/blog",
    meta: indexS91cPXaPJVMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/index.vue")
  },
  {
    name: "unlocalized-showcase-help",
    path: "/help",
    meta: helpEfXlHUDK9GMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/help.vue")
  },
  {
    name: "unlocalized-showcase",
    path: "/",
    meta: indexeWt6Q0yuWpMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/index.vue")
  },
  {
    name: "unlocalized-showcase-leasing",
    path: "/leasing",
    meta: leasingCiCnjeZC8wMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/leasing.vue")
  },
  {
    name: "unlocalized-showcase-map",
    path: "/map",
    meta: mapi1KJwhGxjHMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/map.vue")
  },
  {
    name: "unlocalized-showcase-sharing",
    path: "/sharing",
    meta: sharing1jtvSw0MiFMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/sharing.vue")
  },
  {
    name: "unlocalized-terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionsVjQz7NF1YSMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/terms-and-conditions.vue")
  },
  {
    name: "legal-notice",
    path: "/:locale(ast|de|en|es|fr|it|pt)/legal-notice",
    meta: legal_45noticeNzjtBlpmeWMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/legal-notice.vue")
  },
  {
    name: "privacy-policy",
    path: "/:locale(ast|de|en|es|fr|it|pt)/privacy-policy",
    meta: privacy_45policyvsyiboyGp4Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/privacy-policy.vue")
  },
  {
    name: "referral-leasing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/referral/leasing",
    meta: leasing5FODBNVvN1Meta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/referral/leasing.vue")
  },
  {
    name: "referral-sharing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/referral/sharing",
    meta: sharingKaYMTvQS4rMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/referral/sharing.vue")
  },
  {
    name: "showcase-slug",
    path: "/:locale(ast|de|en|es|fr|it|pt)/:slug()",
    meta: _91slug_934QdgsP0JFgMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/[slug].vue")
  },
  {
    name: "showcase-about",
    path: "/:locale(ast|de|en|es|fr|it|pt)/about",
    meta: aboutuFDbfQmJVSMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/about.vue")
  },
  {
    name: "showcase-blog-slug",
    path: "/:locale(ast|de|en|es|fr|it|pt)/blog/:slug()",
    meta: _91slug_93tFX4DcQbseMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/[slug].vue")
  },
  {
    name: "showcase-blog",
    path: "/:locale(ast|de|en|es|fr|it|pt)/blog",
    meta: indexS91cPXaPJVMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/blog/index.vue")
  },
  {
    name: "showcase-help",
    path: "/:locale(ast|de|en|es|fr|it|pt)/help",
    meta: helpEfXlHUDK9GMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/help.vue")
  },
  {
    name: "showcase",
    path: "/:locale(ast|de|en|es|fr|it|pt)/",
    meta: indexeWt6Q0yuWpMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/index.vue")
  },
  {
    name: "showcase-leasing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/leasing",
    meta: leasingCiCnjeZC8wMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/leasing.vue")
  },
  {
    name: "showcase-map",
    path: "/:locale(ast|de|en|es|fr|it|pt)/map",
    meta: mapi1KJwhGxjHMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/map.vue")
  },
  {
    name: "showcase-sharing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/sharing",
    meta: sharing1jtvSw0MiFMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/showcase/sharing.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/:locale(ast|de|en|es|fr|it|pt)/terms-and-conditions",
    meta: terms_45and_45conditionsVjQz7NF1YSMeta || {},
    component: () => import("/tmp/build/ad8838f6/customer-website-pr/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubEAEw0RKGXaMeta?.name,
    path: "/profile/edit",
    component: component_45stubEAEw0RKGXa
  }
]